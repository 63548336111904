import { computed, observable } from "mobx";

export default class Store {
  @observable
  numberOfLoginAttempt = 0;

  @computed
  get shouldUseCaptcha() {
    return this.numberOfLoginAttempt > 2;
  }
}

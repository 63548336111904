import axios from "axios";
import { getToken } from "./storage.service";
const url = process.env.REACT_APP_API_URL || "http://quizvivor-dev.eu-west-1.elasticbeanstalk.com";

interface CommonHeaderObject {
  Authorization?: string;
  "Content-Language"?: string;
  ["application-id"]: string;
}

const applicationIdConst = process.env.REACT_APP_APPLICATION_ID || "5fa03ee0c133356d8fcd1d70";

const get = async ({ path, auth, language }: { auth?: boolean; path: string; language?: string }) => {
  if (auth && !getToken()) throw "No token for authentified request";
  const headers: CommonHeaderObject = {
    ["application-id"]: `${applicationIdConst}`,
    "Content-Language": `${language}`
  };
  if (auth) headers["Authorization"] = `${getToken()}`;
  const response = await axios.get(`${url}${path}`, { params: { language }, headers });
  return response.data;
};

const post = ({ auth, path, body, language }: { auth?: boolean; path: string; body: any; language?: string }) => {
  if (auth && !getToken()) throw "No token for authentified request";
  const headers: CommonHeaderObject = {
    ["application-id"]: `${applicationIdConst}`,
    "Content-Language": `${language}`
  };
  if (auth) headers["Authorization"] = `${getToken()}`;
  return axios.post(`${url}${path}`, body, { params: { language }, headers });
};

export default {
  get,
  post,
};

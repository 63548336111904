import styled from "styled-components";
import { theme } from "../../theme";

export const MainLegalsMentionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  max-width: 80vw;
  max-height: 80vh;
  min-height: 500px;
  padding: 16px;
`;

export const SlideRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  max-width: 100%;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    min-height: 5vh;
  }
`;

interface Props {
  color: string;
}
export const TitleDivStyle = styled.div<Props>`
  font-size: 4em;
  color: ${theme.color.textStyled};
  text-align: center;
  font-family: ${theme.font.mainFont};
  text-transform: uppercase;
  font-weight: bolder;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  min-height: 80%;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  font-size: 1.5em;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
  }
`;

export const TextBlock = styled.div`
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow: scroll;
`;

export const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  min-width: 100%;
`;

import React from "react";
import { NavigationFooterButton, LogoUpAndGo, ModalComponent, ModalContent } from "../../DumbComponents";
import { Col } from "./Footer.style";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TranslatedContent } from "../../Ducks/application/models/application.model";
import { translateContent } from "../../i18n";
import StandardModal from "../Modal/StandardModal.component";

interface Props {
  buttonArray: { text: string; type: "link" | "download" }[];
  secondaryColor: string;
  legalRules: TranslatedContent[];
  legalMentions: TranslatedContent[];
  privacyPolicy: TranslatedContent[];
}

interface FooterButtons {
  text: string;
  type: "download" | "link";
  downloadLink?: string;
}

const Footer: React.FC<Props> = props => {
  const { t } = useTranslation("answeredQuestions");

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const LEGALS_MENTIONS_BUTTON: FooterButtons = {
    text: t("footer:legalMentions"),
    type: "link",
  };

  const PRIVACY_POLICY: FooterButtons = {
    text: t("footer:privacyPolicy"),
    type: "download",
    downloadLink: translateContent(props.privacyPolicy),
  };

  const GAME_RULES_BUTTON: FooterButtons = {
    text: t("footer:gameRules"),
    type: "download",
    downloadLink: translateContent(props.legalRules),
  };

  const footerLinks = [LEGALS_MENTIONS_BUTTON];
  GAME_RULES_BUTTON.downloadLink && footerLinks.push(GAME_RULES_BUTTON);
  PRIVACY_POLICY.downloadLink && footerLinks.push(PRIVACY_POLICY);

  const footerColsNumber = footerLinks.length + 1;
  const footerColsWidthInPercents = 100 / footerColsNumber;

  return (
    <React.Fragment>
      <StandardModal
        customStyle={{ width: "1600px" }}
        isModalOpen={modalIsOpen}
        customClass={"legals-modal"}
        onClickClose={closeModal}
      >
        <ModalContent
          modalType={"legalMention"}
          legalMentions={translateContent(props.legalMentions)}
          secondaryColor={props.secondaryColor}
        />
      </StandardModal>
      <Col widthInPercents={footerColsWidthInPercents}>
        <LogoUpAndGo imageUrl={"./assets/logo-upandgo-white.png"} />
      </Col>

      {footerLinks.map((textMap, index) => (
        <Col widthInPercents={footerColsWidthInPercents} key={index}>
          <NavigationFooterButton
            buttonType={textMap.type}
            openModal={openModal}
            key={index}
            buttonText={textMap.text}
            link={textMap.downloadLink}
          />
        </Col>
      ))}
    </React.Fragment>
  );
};

export default observer(Footer);

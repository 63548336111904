import errors, { ErrorMap } from "./auth.errors";
import httpService from "../../../services/http.service";
import { Me } from "../../User/models/User.model";
export interface AuthServiceInterface {
  signIn: (props: SignInInput) => Promise<string>;
  signUp: (props: Me) => Promise<Me>;
  signOut: (token: string) => Promise<any>;
}

export interface SignInInput {
  id: string;
  password: string;
  applicationId: string;
  email: string;
}

const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);
  // hash the message. Only getRandomValues() is available on insecure contexts
  if (window.isSecureContext) {
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    const hashHex = hashArray.map(b => ("00" + b.toString(16)).slice(-2)).join("");
    return hashHex;
  } else {
    return message;
  }
};

const signIn = async (input: SignInInput): Promise<string> => {
  if (input.password !== undefined) {
    input.password = await sha256(input.password);
  }
  try {
    const result = await httpService.post({
      path: "/auth/signin",
      body: {
        ...input,
      },
    });
    return result.data.token;
  } catch (e) {
    const code: keyof ErrorMap = e.code;

    throw errors[code] || e;
  }
};

const signUp = async (input: Me): Promise<Me> => {
  if (input.metadata.password !== undefined) {
    input.metadata.password = await sha256(input.metadata.password);
  }
  try {
    const result = await httpService.post({
      path: "/auth/signup",
      body: {
        ...input,
      },
    });
    return result.data.token;
  } catch (e) {
    const code: keyof ErrorMap = e.code;
    throw errors[code] || e;
  }
};

const signOut = async (token: string) => {
  const result = await httpService.post({
    path: "/auth/signout",
    body: {
      token,
    },
  });
  return result.data;
};

export interface PassswordCreationLinkInput {
  email: string;
  context: {
    browserName: string;
    endpoint: string;
    helpUrl: string;
    homepage: string;
    operatingSystem: string;
    productName: string;
    supportEmail: string;
  };
}

const AuthService: AuthServiceInterface = {
  signIn,
  signUp,
  signOut,
};
export default AuthService;

import { observable } from "mobx";
import { ApplicationConfig } from "../models/application.model";
import { AvatarAvailableParts, Avatar } from "../../Avatar/models/avatar.model";

export default class Store {
  @observable
  avatarPartsList?: AvatarAvailableParts;

  @observable
  avastarList: string[] = [];

  @observable
  savedAvatar: Avatar = "https://bp-plateforme.s3.eu-west-1.amazonaws.com/avastars/avastars-2020_jeanclaudedusse.png";

  @observable
  config?: ApplicationConfig;

  @observable
  browserLanguage?: string;

  @observable
  defaultLanguage?: string;

  @observable
  languageList?: string[];

  @observable
  isMuted = false;
}

import styled from "styled-components";
import { theme } from "../../theme";

export const ModalContainer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: auto;
  z-index: 130000;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ContentWindow = styled.div`
  position: relative;
  padding: 1em;
  margin-top: 6em;
  max-width: 800px;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    margin-top: 2em;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-weight: bolder;
  position: absolute;
  right: 2em;
  top: 2em;
  color: #000;
  font-size: 22px;
  z-index: 10000000;
  cursor: pointer;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    right: 1em;
    top: 1em;
  }
`;

import { Me, UserInRanking } from "../models/User.model";
import httpService from "../../../services/http.service";
import { Avatar } from "../../Avatar/models/avatar.model";
export interface UserServicesInterface {
  getMe: () => Promise<Me>;
  getTopUserList: () => Promise<UserInRanking[]>;
  updateAvatar: (avatar: Avatar) => Promise<Avatar>;
}

const getMe = async (): Promise<Me> => {
  const result = await httpService.get({
    auth: true,
    path: "/user/me",
  });
  return {
    id: result._id,
    metadata: result.metadata,
    totalScore: result.totalScore,
    avatar: result.avatar,
    ranking: {
      position: result.ranking?.position || 0,
      numberOfPlayers: result.ranking?.numberOfPlayers || 0,
    },
  };
};

const updateAvatar = async (avatar: Avatar): Promise<Avatar> => {
  const result = await httpService.post({
    auth: true,
    path: "/user/me/avatar",
    body: {
      avatar,
    },
  });
  return result.data.avatar;
};

const getTopUserList = async () => {
  const result = await httpService.get({
    auth: true,
    path: "/user/ranking",
  });
  return result.ranking;
};

const userService: UserServicesInterface = {
  getMe,
  getTopUserList,
  updateAvatar,
};

export default userService;

import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

interface Props extends RouteProps {
  validatingFunction: () => boolean;
  component: React.ComponentType<any>;
  fallbackPath: string;
  bootstrapFunction?: () => void;
}

const PrivateRoute: React.FC<Props> = props => {
  const { component: Component, fallbackPath, validatingFunction, bootstrapFunction, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => {
        if (validatingFunction()) {
          if (bootstrapFunction) bootstrapFunction();
          return <Component {...props} />;
        } else return <Redirect to={{ pathname: fallbackPath, state: { from: props.location } }} />;
      }}
    />
  );
};

export default PrivateRoute;

import { useState, useLayoutEffect } from "react";
import React from "react";
import { UserMetadata } from "./Ducks/application/models/application.model";
import i18next from "i18next";

export const computeAvatarUrlFromIndexes = (
  endpoint: string,
  partLetter: string,
  partIndex: number,
  colorIndex?: number,
) => {
  return `${endpoint}${partLetter}/${partLetter}${partIndex + 1}${
    colorIndex !== undefined ? `-${colorIndex + 1}` : ""
  }.png`;
};

export const getKeys = <T extends {}>(o: T): Array<keyof T> => Object.keys(o) as Array<keyof T>;

export const capitalize = (string: string): string => {
  const lowercase = string.toLowerCase();

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const useWindowPixelRatio = () => {
  const [pixelRatio, setPixelRatio] = useState<{ pixelRatio: number }>({
    pixelRatio: window.devicePixelRatio,
  });

  const handleZoom = () => {
    setPixelRatio({
      pixelRatio: window.devicePixelRatio,
    });
  };

  useLayoutEffect(() => {
    handleZoom();
    window.addEventListener("resize", handleZoom);
    return () => window.removeEventListener("resize", handleZoom);
  }, []);

  return pixelRatio;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  function updateSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return windowSize;
};

export const spanify = (stringBlock: string, customItemClassname?: string) => {
  const stringArray = stringBlock.split("///");
  return stringArray.map((item, index) => (
    <span key={index} className={`${customItemClassname}-${index}`}>
      {item}
    </span>
  ));
};

export const checkField = (userMetadataSchema: UserMetadata, metadataValue?: string) => {
  const errors: string[] = [];
  const PASSWORD_MIN_LENGTH = 8;

  if (!userMetadataSchema || userMetadataSchema.type === "checkbox") return { isValid: true, errors };

  if ((userMetadataSchema.isRequired || userMetadataSchema.isIdentifier) && !metadataValue) {
    errors.push(i18next.t("signUp:formValidation.required"));
  }

  if (metadataValue) {
    if (userMetadataSchema.type === "password" && metadataValue.length < PASSWORD_MIN_LENGTH) {
      errors.push(i18next.t("signUp:formValidation.invalidPasswordLength", { length: PASSWORD_MIN_LENGTH }));
    }
    if (userMetadataSchema.maxLength !== undefined && metadataValue.length > userMetadataSchema.maxLength) {
      errors.push(i18next.t("signUp:formValidation.maxLength", { maxLength: userMetadataSchema.maxLength }));
    }
    if (userMetadataSchema.regex && !new RegExp(userMetadataSchema.regex).test(metadataValue)) {
      errors.push(i18next.t("signUp:formValidation.invalidPattern"));
    }
  }

  return { isValid: !errors.length, errors };
};

import React, { useContext } from "react";
import { CharacterEditorViewer } from "../../../Ducks/Avatar/components/CharacterViewer/CharacterViewer.component";
import LogicContext from "../../../context/context";
import {
  DataContainer,
  AvatarHUDViewerContainer,
  Badge,
  HudContainer,
  HudCentralContainer,
  JokerButtonAssembly,
  NoBadge,
} from "./PlayerHorizontalHud.style";
import StandardText from "../../UI/StandardText";
import { useTranslation } from "react-i18next";
import { JokerButton } from "../..";

type DisplayedData = "score" | "ranking" | "earnings" | "joker" | "accumulatedScoreLabel";

type DataStructure = { illustartionUrl?: string; dataLabel: string; data: JSX.Element[] | JSX.Element };
interface Props {
  leftData: DisplayedData;
  rightData: DisplayedData;
}

const PlayerHorizontalHud: React.FC<Props> = props => {
  const { gameState, gameCore, userState, applicationState } = useContext(LogicContext);
  const { t } = useTranslation("hud");

  const lastAnswer =
    gameState.store.currentGameAnswerResultList[gameState.store.currentGameAnswerResultList.length - 1];

  const createJoker = () => {
    return [
      <JokerButtonAssembly className="joker-counter" key="joker-counter">
        <JokerButton count={gameState.store.jokerAmount} />
      </JokerButtonAssembly>,
    ];
  };

  const getCurrentScoreToString = (): string => {
    try {
      const currentScore = gameCore.getCurrentGameScore();

      return currentScore.toString();
    } catch {
      return "";
    }
  };

  const getCurrentRanking = (): string => {
    const ranking = userState.store.me?.ranking;

    if (!ranking) return "-";

    return `${ranking.position + 1}/${ranking.numberOfPlayers}`;
  };

  const dataTemplate: { [key in DisplayedData]: DataStructure } = {
    accumulatedScoreLabel: {
      illustartionUrl: "./assets/player/score-badge.svg",
      dataLabel: t("hud:accumulatedScoreLabel"),
      data: <StandardText className={"hud-value"} text={userState.store.me?.totalScore.toString() || "0"} />,
    },
    score: {
      illustartionUrl: "./assets/player/score-badge.svg",
      dataLabel: t("hud:scoreLabel"),
      data: <StandardText className={"hud-value"} text={getCurrentScoreToString()} />,
    },
    earnings: {
      illustartionUrl: "./assets/player/trophee-badge.svg",
      dataLabel: t("hud:gainLabel"),
      data: (
        <StandardText className={"hud-value"} text={lastAnswer?.type === "answer" ? `+ ${lastAnswer.points}` : "+ 0"} />
      ),
    },
    ranking: {
      illustartionUrl: "./assets/player/rank-badge.svg",
      dataLabel: t("hud:rankingLabel"),
      data: <StandardText className={"hud-value"} text={getCurrentRanking()} />,
    },
    joker: {
      dataLabel: t("hud:jokerlabel"),
      data: createJoker(),
    },
  };

  const renderData = (type: DisplayedData) => {
    return (
      <DataContainer>
        <StandardText className={"hud-label"} text={dataTemplate[type].dataLabel} />
        {dataTemplate[type].data}
      </DataContainer>
    );
  };

  if (userState.store.me === undefined) return <div />;

  return (
    <div style={{ width: "100%" }} className={"flex-center player-horizontal-hud-container"}>
      <HudContainer>
        {dataTemplate[props.leftData].illustartionUrl ? (
          <Badge className="left badge" src={dataTemplate[props.leftData].illustartionUrl} />
        ) : (
          <div className="left right" style={{ width: "11em" }}></div>
        )}
        <HudCentralContainer>
          <DataContainer className="left">{renderData(props.leftData)}</DataContainer>
          <AvatarHUDViewerContainer className={"avatar-hud-container"}>
            {userState.store.me && typeof userState.store.me?.avatar !== "string" && (
              <CharacterEditorViewer
                heigthProps={"10em"}
                type="avatar"
                urlParts={applicationState.store.avatarPartsList?.url + "/avatar_parts/"}
                clothesIndex={userState.store.me?.avatar?.bodyIndex || 0}
                earsIndex={userState.store.me?.avatar?.earsIndex || 0}
                eyesIndex={userState.store.me?.avatar?.eyesIndex || 0}
                hairIndex={userState.store.me?.avatar?.hairIndex || 0}
                mouthIndex={userState.store.me?.avatar?.mouthIndex || 0}
                noseIndex={userState.store.me?.avatar?.noseIndex || 0}
                pencilIndex={userState.store.me?.avatar?.pencilIndex || 0}
                shapeIndex={userState.store.me?.avatar?.shapeIndex || 0}
              />
            )}
            {typeof userState.store.me?.avatar === "string" && (
              <CharacterEditorViewer heigthProps={"10em"} type={"star"} avastarUrl={userState.store.me?.avatar} />
            )}
          </AvatarHUDViewerContainer>
          <DataContainer className="right">{renderData(props.rightData)}</DataContainer>
        </HudCentralContainer>
        {dataTemplate[props.rightData].illustartionUrl ? (
          <Badge className="badge right" src={dataTemplate[props.rightData].illustartionUrl} />
        ) : (
          <NoBadge className="badge-empty right" style={{ width: "11em" }}></NoBadge>
        )}
      </HudContainer>
    </div>
  );
};

export default PlayerHorizontalHud;

enum AuthError {
  WRONG_LOGIN_OR_PASSWORD,
  UNKNOWN,
}

export interface ErrorMap {
  [key: string]: AuthError;
}

const errors: ErrorMap = {
  "2001": AuthError.WRONG_LOGIN_OR_PASSWORD,
};

export default errors;

import React, { useState } from "react";
import { AnswerContainer, IconContainer, QuestionTextContainer } from "./Answer.style";

interface Props {
  id: string;
  answer: string;
  onClick?: (id: string) => void;
  correction?: { isCorrect: boolean; isSelected: boolean };
  disabled?: boolean;
}

const AnswerComponent: React.FC<Props> = props => {
  const [isChoosen, setChoosenCard] = useState<boolean>(false);

  const selectedClx = props.correction?.isSelected ? "selected" : "";
  const resultClx = props.correction ? (props.correction.isCorrect ? "correct" : "wrong") : "";

  const onClick = () => {
    if (props.disabled) return;
    if (props.onClick) props.onClick(props.id);
    setChoosenCard(true);
  };
  return (
    <AnswerContainer
      id={`answer_${props.id}`}
      className={`rounded ${resultClx} ${selectedClx} answer-item`}
      onClick={onClick}
      correct={true}
      isChoosen={isChoosen}
      disabled={props.disabled}
    >
      {props.correction && (
        <IconContainer className="icon-container">
          {props.correction.isCorrect ? (
            <img src="./assets/game/correct-mark.svg" />
          ) : (
            <img src="./assets/game/wrong-mark.svg" />
          )}
        </IconContainer>
      )}
      <QuestionTextContainer className="answer-text-container">{props.answer}</QuestionTextContainer>
    </AnswerContainer>
  );
};

export default AnswerComponent;

import React from "react";
import { GeometricBackGroundStyle } from "./GeometricBackground.style";
interface Props {
  backgroundUrl: string;
}

const GeometricBackground: React.FC<Props> = props => {
  return <GeometricBackGroundStyle backGroundUrl={props.backgroundUrl} />;
};

export default GeometricBackground;

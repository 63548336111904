import React from "react";

type Route = {
  view: React.LazyExoticComponent<React.FC<any>>;
  path: string;
};

//// HOME PRIVATE ROUTES ///////////

export const GAME: Route = {
  view: React.lazy(() => import("../Ducks/game/views/Game.view")),
  path: "/game",
};

export const HOME: Route = {
  view: React.lazy(() => import("../Ducks/game/views/Home.view")),
  path: "/",
};

export const UPDATEAVATAR: Route = {
  view: React.lazy(() => import("../Ducks/AvatarPicker/view/AvatarPicker.view")),
  path: "/updateavatar",
};

export const GAME_ROUTES = {
  UPDATEAVATAR,
  GAME,
  HOME,
};

//// PUBLIC ROUTES /////////////

export const AUTH: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/signup/SignUp.view")),
  path: "/auth/signup",
};
export const LOGIN: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/login/Login.view")),
  path: "/auth/login",
};

export const AVATAR_CREATOR: Route = {
  view: React.lazy(() => import("../Ducks/Avatar/view/Avatar.view")),
  path: "/auth/avatar/new",
};

export const AVATAR: Route = {
  view: React.lazy(() => import("../Ducks/AvatarPicker/view/AvatarPicker.view")),
  path: "/auth/avatar",
};

export const LANDING: Route = {
  view: React.lazy(() => import("../Ducks/Landing/view/LandingPage.view")),
  path: "/auth",
};

export const PUBLIC_ROUTES = {
  AVATAR,
  AVATAR_CREATOR,
  LOGIN,
  AUTH,
  LANDING,
};

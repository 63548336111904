import React from "react";
import { Image3D } from "../../styles/FX/3D/Rotation3dY.style";
import { JokerDiv } from "./Joker.style";
import { GameScreen } from "../../Ducks/game/models/game.model";

interface JokerProps {
  jokerLength?: number;
  glowingID?: number;
  hudType: GameScreen;
  withAnimation?: boolean;
}

const Joker: React.FC<JokerProps> = props => {
  return (
    <JokerDiv className={"joker-div"}>
      <Image3D
        glowing={
          props.hudType !== "finalIntro" && props.jokerLength !== undefined && props.glowingID === props.jokerLength - 1
        }
        url={"./assets/game/joker.svg"}
        withAnimation={props.withAnimation}
      />
    </JokerDiv>
  );
};
Joker.defaultProps = {
  withAnimation: true,
};

export default Joker;

import React from "react";
import { JokerButtonDiv, JokerCounter, JokerImg } from "./JokerButton.style";

interface Props {
  useJoker?: () => void;
  count?: number;
  widthInPx?: number;
}

const JokerButton: React.FC<Props> = props => {
  const useJoker = (event: React.MouseEvent) => {
    event.preventDefault();
    props.useJoker && props.useJoker();
  };

  return (
    <JokerButtonDiv className="joker-btn" width={props.widthInPx} onClick={props.useJoker && useJoker}>
      <JokerImg className="joker-img" src="./assets/game/joker.svg" />
      {props.count !== undefined && <JokerCounter className="joker-counter">{props.count}</JokerCounter>}
    </JokerButtonDiv>
  );
};

export default JokerButton;

import React from "react";
import { StandardButtonStyle } from "./StandardButton.style";

interface Props {
  value: string;
  buttonColor: string;
  onClick?: (event: React.MouseEvent) => void;
  onSubmit?: () => void;
  onMouseOver?: (event: React.MouseEvent) => void;
  onMouseOut?: (event: React.MouseEvent) => void;
  confirmAction?: () => void;
}

const StandardButton: React.FC<Props> = props => {
  return (
    <StandardButtonStyle
      onMouseOut={props.onMouseOut}
      onMouseOver={props.onMouseOver}
      onClick={props.onClick}
      secondaryColor={props.buttonColor}
      onSubmit={props.onSubmit}
    >
      <div style={{ alignSelf: "center" }}>{props.value}</div>
    </StandardButtonStyle>
  );
};

export default StandardButton;

import React from "react";
import { Image3D } from "../../styles/FX/3D/Rotation3dY.style";
import { FifthyDiv } from "./FifthyFifthy.style";

const FifthyFifthy: React.FC = () => {
  return (
    <FifthyDiv>
      <Image3D url={"./assets/fifthy.png"} />
    </FifthyDiv>
  );
};

export default FifthyFifthy;

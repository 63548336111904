import React from "react";
import { FooterButtonDiv, FooterUrlLinkDiv } from "./NavigationFooterButton.style";
interface Props {
  buttonText: string;
  openModal?: () => void;
  buttonType: "download" | "link";
  link?: string;
}

const NavigationButtonLink: React.FC<Props> = props => {
  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    props.openModal && props.openModal();
  };
  if (props.buttonType === "link") return <FooterButtonDiv onClick={openModal}>{props.buttonText} </FooterButtonDiv>;
  if (props.buttonType === "download") {
    return (
      <FooterUrlLinkDiv href={props.link} target="_blank">
        {props.buttonText}
      </FooterUrlLinkDiv>
    );
  }
  return <div></div>;
};

export default NavigationButtonLink;

import { Me } from "../Ducks/User/models/User.model";
import jwt from "jsonwebtoken";
// import Cookies from "universal-cookie";

export const STORAGE_KEYS = { TOKEN: "QV-token", USER_INFOS: "QV-userInfos", CURRENT_LANG: "QV-currentLang" };
// const cookies = new Cookies();
export interface StorageServiceInterface {
  storeToken: (token: string) => void;
  getToken: () => string | null;
  storeCurrentLanguage: (lang: string) => void;
  getCurrentLanguage: () => string | null;
  clearToken: () => void;
  storeUserAuthInfos: (infos: Me) => void;
  getUserAuthInfos: () => Me | null;
  clearUserAuthInfos: () => void;
  isTokenValid: (token: string) => boolean;
  clearLocalData: () => void;
}

const storeToken = (token: string) => {
  // cookies.set(STORAGE_KEYS.TOKEN, token);
  localStorage.setItem(STORAGE_KEYS.TOKEN, token);
};

export const getToken = () => {
  // return cookies.get(STORAGE_KEYS.TOKEN);
  return localStorage.getItem(STORAGE_KEYS.TOKEN);
};

const storeCurrentLanguage = (lang: string) => {
  localStorage.setItem(STORAGE_KEYS.CURRENT_LANG, lang);
};

const getCurrentLanguage = () => {
  return localStorage.getItem(STORAGE_KEYS.CURRENT_LANG);
};

const clearCurrentLanguage = () => {
  localStorage.removeItem(STORAGE_KEYS.CURRENT_LANG);
};

const clearToken = () => {
  // cookies.remove(STORAGE_KEYS.TOKEN);
  localStorage.removeItem(STORAGE_KEYS.TOKEN);
};

const storeUserAuthInfos = (infos: Me) => {
  localStorage.setItem(STORAGE_KEYS.USER_INFOS, JSON.stringify(infos));
};

const getUserAuthInfos = (): Me | null => {
  const infos = localStorage.getItem(STORAGE_KEYS.USER_INFOS);
  return infos ? JSON.parse(infos) : null;
};

const clearUserAuthInfos = () => {
  localStorage.removeItem(STORAGE_KEYS.USER_INFOS);
};

const clearLocalData = () => {
  clearUserAuthInfos();
  clearToken();
  clearCurrentLanguage();
};

const isTokenValid = (token: string) => {
  if (token === null) return false;
  const payload: any = jwt.decode(token);
  if (payload) {
    if (Math.round(new Date().getTime() / 1000) > payload.exp) return false;
    return true;
  } else {
    return false;
  }
};

export default {
  storeToken,
  clearToken,
  storeUserAuthInfos,
  getUserAuthInfos,
  clearUserAuthInfos,
  getToken,
  isTokenValid,
  storeCurrentLanguage,
  getCurrentLanguage,
  clearLocalData,
};

import { AvatarAvailableParts } from "../../Avatar/models/avatar.model";
import { ApplicationConfig } from "../models/application.model";
import httpService from "../../../services/http.service";

export interface ApplicationServicesInterface {
  getApplicationConfig: (browserLanguage?: string) => Promise<ApplicationConfig>;
  getAvailableAvatars: () => Promise<AvatarAvailableParts>;
  getAvastarList: () => Promise<string[]>;
}

const getApplicationConfig = async (browserCurrentLanguage?: string): Promise<ApplicationConfig> => {
  const configHttpService = await httpService.get({
    auth: false,
    path: "/application/config",
    language: browserCurrentLanguage !== undefined && browserCurrentLanguage !== "" ? browserCurrentLanguage : "",
  });
  return {
    _id: configHttpService._id,
    applicationName: configHttpService.applicationName,
    branding: {
      primaryColor: configHttpService.branding.primaryColor,
      secondaryColor: configHttpService.branding.primaryColor,
      logo: configHttpService.branding.logo,
      logoLink: "https://fr.wikipedia.org/wiki/Fichier:Logo_Carrefour.svg",
      trailer: {
        type: configHttpService.branding.trailer.type,
        url: configHttpService.branding.trailer.url,
        poster: configHttpService.branding.trailer.poster,
      },
      legalRules: configHttpService.legalRules || [],
      legalMentions: configHttpService.legalMentions || [],
      legalPrivacyPolicy: configHttpService.legalPrivacyPolicy || [],
      startDate: configHttpService.branding.startDate,
      stopDate: configHttpService.branding.stopDate,
    },
    userMetadataScheme: configHttpService.userMetadataScheme,
    jackpotAmount: configHttpService.jackpotAmount,
    defaultLanguage: configHttpService.defaultLanguage,
    languageList: configHttpService.languageList,
  };
};

const getAvailableAvatars = async (): Promise<AvatarAvailableParts> => {
  const avatarHttpService = await httpService.get({
    auth: false,
    path: "/application/avatarParts",
  });
  return {
    url: avatarHttpService.endpoint,
    parts: {
      hair: avatarHttpService.urls.hair,
      pencil: avatarHttpService.urls.pencil,
      body: avatarHttpService.urls.body,
      ears: avatarHttpService.urls.ears,
      eyes: avatarHttpService.urls.eyes,
      mouth: avatarHttpService.urls.mouth,
      nose: avatarHttpService.urls.nose,
      shape: avatarHttpService.urls.shape,
    },
  };
};

const getAvastarList = async (): Promise<string[]> => {
  const result = await httpService.get({
    auth: false,
    path: "/application/avastars",
  });
  return result.urls.map((url: string) => `${result.endpoint}/${url}`);
};

const applicationService: ApplicationServicesInterface = {
  getApplicationConfig,
  getAvailableAvatars,
  getAvastarList,
};

export default applicationService;

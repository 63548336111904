import "mobx-react-lite/batchingForReactDom";
import React, { Suspense, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Spinner } from "./DumbComponents";
import { PrivateRoute } from "./routing";
import LogicContext from "./context/context";
import applicationCore from "./Ducks/application/core";
import applicationState from "./Ducks/application/state";
import authCore from "./Ducks/Auth/core";
import authState from "./Ducks/Auth/state";
import gameCore from "./Ducks/game/core";
import gameState from "./Ducks/game/state";
import userCore from "./Ducks/User/core";
import userState from "./Ducks/User/state";
const publicContainer = React.lazy(() => import("./containers/public"));
const homeContainer = React.lazy(() => import("./containers/game"));

const App: React.FC = () => {
  const setApp = async () => {
    (await applicationCore.setApplicationConfig()) &&
      (await applicationCore.setAssetsPartsList()) &&
      (await applicationCore.setAvailableAvastarList());
  };

  useEffect(() => {
    setApp();
  }, []);

  const bootstrapFunction = () => {
    userCore.setMe();
  };

  useEffect(() => {
    bootstrapFunction();
  }, []);

  const playerValidatingFunction = () => userCore.canAccessContainer("authorized");
  return (
    <I18nextProvider i18n={i18next}>
      <LogicContext.Provider
        value={{ applicationCore, applicationState, authCore, gameCore, gameState, userCore, userState, authState }}
      >
        <Router>
          <div>
            <Suspense fallback={<Spinner />}>
              <Switch>
                <Route path={"/auth"} component={publicContainer} />
                <PrivateRoute
                  fallbackPath={"/auth"}
                  path="/"
                  component={homeContainer}
                  validatingFunction={playerValidatingFunction}
                  bootstrapFunction={bootstrapFunction}
                />
              </Switch>
            </Suspense>
          </div>
        </Router>
      </LogicContext.Provider>
    </I18nextProvider>
  );
};

export default App;

import AuthStore from "./auth.store";
import { action } from "mobx";

export interface AuthMutatorInterface {
  setNumberOfLoginAttempt: (n: number) => void;
}

export default class Mutator implements AuthMutatorInterface {
  private readonly authStore: AuthStore;

  constructor(props: { authStore: AuthStore }) {
    this.authStore = props.authStore;
  }

  @action
  setNumberOfLoginAttempt(attempts: number) {
    this.authStore.numberOfLoginAttempt = attempts;
  }
}

import { err, ok } from "neverthrow";
import { StorageServiceInterface } from "../../../services/storage.service";
import { AuthServiceInterface } from "../services/auth.service";
import { UserMetadata } from "../../application/models/application.model";

import { SignInInput } from "../services/auth.service";
import { Me } from "../../User/models/User.model";
import { AuthStateInterface } from "../state";
import userCore from "../../User/core";

interface AuthCoreInterface {
  storageService: StorageServiceInterface;
  authService: AuthServiceInterface;
  authState: AuthStateInterface;
}

const Core = ({ storageService, authService, authState }: AuthCoreInterface) => {
  const signIn = async (input: SignInInput) => {
    try {
      const token = await authService.signIn(input);
      storageService.storeToken(token);
      authState.mutator.setNumberOfLoginAttempt(0);
      return ok("ok");
    } catch (e) {
      authState.mutator.setNumberOfLoginAttempt(authState.store.numberOfLoginAttempt + 1);
      return err("Can't login with these identifiers");
    }
  };
  const checkFields = (input: UserMetadata[], me: Me) =>
    input.reduce((error, field) => {
      if (field.type !== "checkbox") {
        if (field.isRequired && (!me.metadata[field.name] || me.metadata[field.name].length === 0))
          error += `${field.name} is required\n`;
        if (field.maxLength !== undefined && me.metadata[field.name].length > field.maxLength)
          error += `${field.name} is ${field.maxLength} max character\n`;
        if (field.regex && !new RegExp(field.regex).test(me.metadata[field.name]))
          error += `${field.name} is badly formatted\n`;
      }
      return error;
    }, "") || undefined;

  const signUp = async (input: Me, inputMetadata: UserMetadata[]) => {
    try {
      const error = checkFields(inputMetadata, input);
      if (error) return err(error);
      const token = await authService.signUp(input);
      storageService.storeToken((token as unknown) as string);
      return ok("ok");
    } catch (e) {
      return err(e);
    }
  };

  const signOut = () => {
    storageService.clearLocalData();
    userCore.clearMe();

    return ok("ok");
  };

  return {
    signIn,
    signUp,
    signOut,
  };
};

export default Core;

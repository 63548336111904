const asset = (filename: string) => process.env.PUBLIC_URL + "/assets" + filename;

const soundList = {
  newJoker: "/sound/earnJoker.mp3",
  goodAnswer: "/sound/goodAnswer.mp3",
  openLock: "/sound/openLock.mp3",
  papagayo: "/sound/papagayoApparition.mp3",
  roundIntro: "/sound/roundIntro.mp3",
  success: "/sound/success.mp3",
  superBonusWin: "/sound/superBonusWin.mp3",
  wrongAnswer: "/sound/wrongAnswer.mp3",
};

const loopSoundList = {
  chrono: "/sound/chrono.mp3",
};

const audio = new Audio();

export const playSound = (key: keyof typeof soundList) => {
  audio.src = asset(soundList[key]);
  audio.loop = false;
  audio.play();
};

export const playLoopSound = (key: keyof typeof loopSoundList) => {
  audio.src = asset(loopSoundList[key]);
  audio.loop = true;
  audio.play();
};

export const muteAllSound = (mute: boolean) => {
  const arrayOfSound = Object.keys(soundList);

  for (let i = 0; i < arrayOfSound.length; i++) {
    audio.src = asset(arrayOfSound[i]);
    if (mute === true) {
      audio.muted = true;
    } else {
      audio.muted = false;
    }
  }
};

import { err, ok } from "neverthrow";
import { ApplicationServicesInterface } from "../services/application.service";
import { ApplicationStateInterface } from "../state";
import { Avatar } from "../../Avatar/models/avatar.model";
import {
  DEFAULT_LANGUAGE,
  getBrowserLanguageFromil8nIndex as getBrowserLanguageFromil8next,
} from "../../../i18n/index";
//import i18n from "i18next";
import { changeLang } from "../../../i18n";
import storageService from "../../../services/storage.service";

interface Props {
  applicationService: ApplicationServicesInterface;
  applicationState: ApplicationStateInterface;
}

const Core = ({ applicationService, applicationState }: Props) => {
  const setAssetsPartsList = async () => {
    const avatarPartsList = await applicationService.getAvailableAvatars();
    applicationState.mutator.setAssetsPartsList(avatarPartsList);
    return ok(avatarPartsList);
  };

  const setAvailableAvastarList = async () => {
    const list = await applicationService.getAvastarList();
    applicationState.mutator.setAvailableAvastarList(list);
    return ok(list);
  };

  const setApplicationConfig = async () => {
    try {
      const browserCurrentLanguageGetFromi18lNext = getBrowserLanguageFromil8next();

      const applicationConfig = await applicationService.getApplicationConfig(
        browserCurrentLanguageGetFromi18lNext as string,
      );
      if (applicationConfig.languageList !== undefined) {
        applicationState.mutator.setLanguageList(applicationConfig.languageList);
      }
      if (applicationConfig.defaultLanguage !== undefined) {
        applicationState.mutator.setDefaultLanguage(applicationConfig.defaultLanguage);
      }
      changeLang(storageService.getCurrentLanguage() || applicationConfig.defaultLanguage || DEFAULT_LANGUAGE);
      applicationState.mutator.setConfig(applicationConfig);
      return ok(applicationConfig);
    } catch (e) {
      return err(`${e.message}`);
    }
  };

  const setBrowserLanguage = () => {
    try {
      const browserLanguage = getBrowserLanguageFromil8next();
      applicationState.mutator.setBrowserLanguage(browserLanguage as string);
      return ok(browserLanguage);
    } catch (e) {
      return err(`${e.message}`);
    }
  };

  const saveAvatarParts = (avatarInputCoreSchema: Avatar) => {
    applicationState.mutator.saveAvatar(avatarInputCoreSchema);
  };

  return {
    setAssetsPartsList,
    setApplicationConfig,
    saveAvatarParts,
    setAvailableAvastarList,
    setBrowserLanguage,
  };
};

export default Core;

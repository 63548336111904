import React from "react";
import { MainLegalsMentionsContainer, TextBlock, TitleDivStyle } from "./LegalMentions.style";

interface Props {
  title: string;
  text: string;
  secondaryColor: string;
}

const LegalsMentions: React.FC<Props> = props => {
  const createMarkup = () => {
    return { __html: props.text };
  };
  return (
    <>
      <MainLegalsMentionsContainer>
        <TitleDivStyle color={props.secondaryColor}>{props.title}</TitleDivStyle>
        <TextBlock dangerouslySetInnerHTML={createMarkup()} />
      </MainLegalsMentionsContainer>
    </>
  );
};

export default LegalsMentions;

import { observable } from "mobx";
import { Me, UserInRanking } from "../models/User.model";

export default class Store {
  @observable
  me?: Me;

  @observable
  displayName = "";

  @observable
  topUserList: UserInRanking[] = [];
}

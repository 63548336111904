import UserStore from "./User.store";
import { action } from "mobx";
import { Me, UserInRanking } from "../models/User.model";

export interface UserMutatorInterface {
  setMe: (user: Me | undefined, displayName: string) => void;
  setTopUserList: (list: UserInRanking[]) => void;
}

export default class Mutator implements UserMutatorInterface {
  private readonly userStore: UserStore;

  constructor(props: { userStore: UserStore }) {
    this.userStore = props.userStore;
  }

  @action
  setMe(user: Me | undefined, displayName: string) {
    this.userStore.me = user;
    this.userStore.displayName = displayName;
  }

  @action
  setTopUserList(list: UserInRanking[]) {
    this.userStore.topUserList = list;
  }
}

import styled from "styled-components";

export const CheckIconDiv = styled.div`
  background: url("./assets/greenCheck.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  height: 100%;
  width: 100%;
`;

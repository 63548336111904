import Store from "./User.store";
import Mutator from "./User.mutator";

const store = new Store();
const mutator = new Mutator({ userStore: store });

export interface UserStateInterface {
  store: Store;
  mutator: Mutator;
}

export default {
  store,
  mutator,
};


import Core from "./application.core";
import ApplicationState from "../state";
import applicationService from "../services/application.service";

const applicationCore = Core({
  applicationState: ApplicationState,
  applicationService,
});

export default applicationCore;

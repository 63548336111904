import { err, ok } from "neverthrow";
import { UserServicesInterface } from "../services/User.service";
import { UserStateInterface } from "../state/index";
import { StorageServiceInterface } from "../../../services/storage.service";
import { UserType } from "../models/User.model";
import { Avatar } from "../../Avatar/models/avatar.model";
import { ApplicationStateInterface } from "../../application/state";

interface UserCoreInterface {
  userService: UserServicesInterface;
  userState: UserStateInterface;
  storageService: StorageServiceInterface;
  applicationState: ApplicationStateInterface;
}

const Core = ({ userService, userState, storageService, applicationState }: UserCoreInterface) => {
  const setMe = async () => {
    try {
      if (!storageService.getToken()) return;
      const user = await userService.getMe();
      const displayName = applicationState.store.config?.userMetadataScheme
        .reduce(
          (displayName, currentMeta) =>
            currentMeta.isDisplayed ? `${displayName} ${user.metadata[currentMeta.name]}` : displayName,
          "",
        )
        .trim();
      userState.mutator.setMe(user, displayName !== undefined ? displayName : "");
      return ok(user);
    } catch (e) {
      return err(`${e.message}`);
    }
  };

  const checkdate = () => {
    if (applicationState.store.config?.branding.startDate) {
      return new Date(Date.now()) < applicationState.store.config?.branding.startDate;
    }
    if (applicationState.store.config?.branding.stopDate) {
      return new Date(Date.now()) > applicationState.store.config?.branding.stopDate;
    }
    return false;
  };

  const canAccessContainer = (type: UserType) => {
    const token = storageService.getToken();
    if (checkdate()) return false;
    if (token === null) return false;
    if (!storageService.isTokenValid(token)) return false;
    if (token) {
      if (type === "unauthorized") return false;
      return true;
    } else {
      return false;
    }
  };

  const setTopUserList = async () => {
    try {
      const ranking = await userService.getTopUserList();
      userState.mutator.setTopUserList(ranking);
      return ok(ranking);
    } catch (e) {
      console.error(e);
      return err(e);
    }
  };

  const updateAvatar = async (avatar: Avatar) => {
    try {
      if (!userState.store.me) throw "No user";
      const modifiedAvatar = await userService.updateAvatar(avatar);
      userState.mutator.setMe({ ...userState.store.me, avatar: modifiedAvatar }, userState.store.displayName);
      return ok(modifiedAvatar);
    } catch (e) {
      return err(e);
    }
  };

  const clearMe = () => {
    userState.mutator.setMe(undefined, "");
  };

  return {
    setMe,
    clearMe,
    canAccessContainer,
    setTopUserList,
    updateAvatar,
  };
};

export default Core;

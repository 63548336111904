import Core from "./auth.core";
import authState from "../state";
import authService from "../services/auth.service";
import storageService from "../../../services/storage.service";

const authCore = Core({
  storageService,
  authService,
  authState,
});

export default authCore;
